<template>
  <page-header-wrapper :title='this.$route.query.title || this.$route.meta.title'>
    <a-form-model ref='form' :model='form' layout='inline'>

      <a-form-model-item prop='production'>
        <a-select v-model='form.production'
                  show-search
                  placeholder="请选择"
                  :default-active-first-option='false'
                  :filter-option='false'
                  :allowClear='true'
                  @change="handleChange1"
                  @search='handleSearch1'
                  style="width: 200px">
          <a-select-option v-for='item in prodList' :key='item.depositoryProduction'>
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item prop='type'>
        <a-select v-model="form.type" placeholder="全部类型" style="width: 250px">
          <a-select-option :value="undefined">
            全部类型
          </a-select-option>
<!--          <a-select-option :value="6">-->
<!--            人工出库-->
<!--          </a-select-option>-->
<!--          <a-select-option :value="1">-->
<!--            手动入库-->
<!--          </a-select-option>-->
          <a-select-option :value="2">
            订单出库
          </a-select-option>
          <a-select-option :value="7">
            业务赠送
          </a-select-option>
          <a-select-option :value="8">
            合创出库
          </a-select-option>
          <a-select-option :value="9">
            退换货
          </a-select-option>
          <a-select-option :value="10">
            门票出库
          </a-select-option>
          <a-select-option :value="3">
            40交接20
          </a-select-option>
          <a-select-option :value="6">
            删除订单
          </a-select-option>
<!--          <a-select-option :value="4">-->
<!--            活动报名-->
<!--          </a-select-option>-->

<!--          <a-select-option :value="5">-->
<!--            交接出库-->
<!--          </a-select-option>-->
        </a-select>
      </a-form-model-item>


        <a-form-model-item prop='start'>
          <a-range-picker
            v-model="dateArr"
            :format="[formatType, formatType]"
            :show-time="{
            hideDisabledOptions: true
          }"
            :disabled-date="disabledDate"
            @change="dateChange"
          />
        </a-form-model-item>

      <a-form-model-item prop='orderId'>
        <a-input v-model='form.orderId' style="width: 300px" placeholder="订单号" />
      </a-form-model-item>

      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
      </div>
      <s-table ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: "100%"}' :data='loadData'>
        <span slot='name' slot-scope='text'>
          <template>
            <Ellipsis tooltip :length='20'>{{ text }}</Ellipsis>
          </template>
        </span>
        <span slot='beforeCount' slot-scope='text, record'>
          <template>
            {{parseInt(text) +parseInt(record.storageCount)}}
          </template>
        </span>
      </s-table>
    </div>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import {queryInsideStorehouseProd, queryInsideStorehouseProdList} from '@/api/inside'
import moment from "moment";

const columns = [
  {
    width: 150,
    title: '商品名称',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' }
  },
  {
    width: 100,
    title: '类型',
    dataIndex: 'typeName',
    scopedSlots: { customRender: 'typeName' }
  },
  {
    width: 100,
    title: '订单号',
    dataIndex: 'orderId',
    scopedSlots: { customRender: 'orderId' }
  },
  {
    width: 100,
    title: '变动数量',
    dataIndex: 'storageCount'
  },
  {
    width: 100,
    title: '剩余数量',
    dataIndex: 'beforeCount',
    scopedSlots: { customRender: 'beforeCount' }
  },
  {
    width: 100,
    title: '备注',
    dataIndex: 'remark'
  },
  {
    width: 150,
    title: '时间',
    dataIndex: 'createTime'
  },
  {
    width: 150,
    title: '操作人',
    dataIndex: 'opUsername',
  }
]

const formatType = 'YYYY-MM-DD';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

export default {
  name: 'InsideStorehouseChildEdit',
  components: {
    STable, Ellipsis
  },
  data() {
    return {
      // 筛选表单
      formatType,
      dateArr: [baseStart, baseEnd],
      // 表头
      columns,
      form: {
        start: baseStart,
        end: baseEnd
      },
      prodList: [],
      // 加载数据方法 必须为 Promise 对象
      queryParam: { isDefault: 1 },
      loadData: parameter => {
        parameter.pageNum = parameter.current;
        parameter.pageSize = parameter.size;
        parameter.pageMode = 0;
        parameter.production = this.production;
        let param = Object.assign(parameter, this.queryParam, this.form);
        // debugger;
        return queryInsideStorehouseProdList(param)
          .then(datum => {
            return datum
          })
      },
    }
  },
  created() {
    this.production = this.$route.query.depositoryProduction
    this.handleSearch1('');
  },
  activated() {
    // debugger;
    this.production = this.$route.query.depositoryProduction
    this.handleSearch1('');
  },
  methods: {
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
    },
    dateChange() {
      this.form.start =  moment(this.dateArr[0]).startOf('day').format(this.formatType + " HH:mm");
      this.form.end =  moment(this.dateArr[1]).endOf('day').format(this.formatType+ " HH:mm");
    },
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    handleChange1(e) {
      this.$set(this.form, "production", e)
    },
    handleSearch1(production) {
      queryInsideStorehouseProd({ pageNum: 1, pageSize: 9999, pageMode: 0,isDefault:1, production: production }).then(result => {
        this.prodList = result.records;
      })
      if(this.$refs.table){
        this.$refs.table.refresh(true)
      }
    },
  }
}
</script>
